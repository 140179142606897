export class AlertSettings {
    bookingConfirmation: boolean;
    bookingCancellation: boolean;
    bookingModification: boolean;
    bookingSummary: boolean;
    emails: string;

    constructor() {
        this.bookingConfirmation = true;
        this.bookingCancellation = false;
        this.bookingModification = false;
        this.bookingSummary = true;
        this.emails = '';
    }
}
