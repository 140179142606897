const STREET_NUMBER_SEPARATOR = ' ';
const ADDRESS_SEPARATOR = ', ';
const ZIPCODE_CITY_SEPARATOR = ' ';

export class Address {
    public street: string;
    public streetNumber: string;
    public city: string;
    public zipCode: string;
    public country: string;

    public formatAddress(): string {
        return this.street + STREET_NUMBER_SEPARATOR + this.streetNumber
            + ADDRESS_SEPARATOR + this.zipCode + ZIPCODE_CITY_SEPARATOR + this.city + ADDRESS_SEPARATOR + this.country;
    }
}
