import { HttpInterceptorFn, HttpResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { map } from 'rxjs';
import { ApiConfig } from '../config/api-config';

export const jsonInterceptor: HttpInterceptorFn = (req, next) => {  
  if (req.responseType === "json" && isApiUri(req.url)) {
    return next(req.clone({ responseType: "text" }))
      .pipe(
        map(event => event instanceof HttpResponse
          && event.ok
          && typeof event.body === "string"
          ? event.clone({ body: parseBody(event.body) })
          : event)
      )
  }

  return next(req);
};

function parseBody(body: string) {
  const dateStringRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:.\d+)?(?:Z|(\+|-)([\d|:]*))?$/;
  if (body === "")
    return null;

  return JSON.parse(body, (key, value) => {
    if (typeof value === 'string' && dateStringRegex.test(value)) {
      return new Date(value);
    }

    return value;
  });
}

function isApiUri(url: string) {
  const apiConfig: ApiConfig = inject(ApiConfig);
  for (const iterator of Object.values(apiConfig) as string[]) {
    if (url.startsWith(iterator))
      return true;
  }

  return false;
}