
export class Store {

  public storeIdNumber?: number;

  public name?: string;

  public publicName?: string;

  // TODO Create Address class
  public address?: any;

  // TODO Create Geolocation class
  public geolocation?: any;

  public phone?: string;

  public matchesCriteria?: boolean;
  
}
