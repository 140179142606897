import { HttpClient } from "@angular/common/http";
import { TranslateLoader } from "@ngx-translate/core";
import { assign } from "lodash-es";
import { forkJoin, map, Observable } from "rxjs";

export class Resource {
    prefix: string;
    suffix: string;
}

export class MultipleHttpTranslateLoader implements TranslateLoader {

    private resources: Resource[];

    constructor(
        private readonly http: HttpClient,
        resources: Resource[]
    ) {
        this.resources = resources;
    }

    getTranslation(lang: string): Observable<Object> {
        return forkJoin(
            this.resources.map(x => this.http.get(x.prefix + lang + x.suffix))
        ).pipe(
            map(resp => assign({}, ...resp))
        );
    }
    
}